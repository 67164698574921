<template>
  <div class="DarkWhite">
      <v-row row>
        <v-col cols="12">
          <span class="d-flex mb-5">
            <span>
             Budget Allocation Information
            </span>
            <v-divider class="mx-1 mt-3"></v-divider>
          </span>
          <v-row>
            <v-col
            cols="12" sm="6" md="3"
            v-for="item in items1"
            :key="item.text">
              <v-card
                class="d-flex flex-row pa-2 rounded-lg pt-4"
                outlined
              >
                <div
                  :class="item.colors"
                  class="Offset pa-2 rounded-lg d-flex align-center"
                >
                  <v-icon dark large>{{ item.icon }}</v-icon>
                </div>
                <div class="d-flex flex-column">
                  <div class="pa-1 ml-2 font-weight-bold text-caption text-uppercase">
                    {{ item.text }}
                  </div>
                  <v-divider class="ml-2"></v-divider>
                  <div class="pa-1 ml-2 text-caption">
                    {{ item.counter }}
                  </div>
                </div>
              </v-card>
            </v-col>
            <v-col
            cols="12" sm="6" md="3"
            v-for="item in items2"
            :key="item.text"
            >
              <v-card
                class="d-flex flex-row pa-2 rounded-lg pt-4"
                outlined
              >
                <div
                  :class="item.colors"
                  class="Offset pa-2 rounded-lg d-flex align-center"
                >
                  <v-icon dark large>{{ item.icon }}</v-icon>
                </div>
                <div class="d-flex flex-column">
                  <div class="pa-1 ml-2 font-weight-bold text-caption text-uppercase">
                    {{ item.text }}
                  </div>
                  <v-divider class="ml-2"></v-divider>
                  <div class="pa-1 ml-2 text-caption">
                    {{ item.counter }}
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12">
        <v-card class="card-style" elevation="0" outlined>
            <v-list-item three-line>
              <v-list-item-content class="ml-5">
                <v-list-item-title class="text-h5 my-2">
                  Monthly Purchase Request <small> - <b>{{mpr_data.total || 0}}</b> Total PRs</small>
                </v-list-item-title>
                <v-list-item-subtitle color="#808191" class="text-subtitle-1">
                  <BarChart :content="mpr_data" />
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
        </v-card>
      </v-col>
      </v-row>

      <span class="d-flex my-5">
        <span>
          PPMP and PR Information
        </span>
        <v-divider class="mx-1 mt-3"></v-divider>
      </span>
      <v-row>
        <v-col cols="12" md="6" lg="4">
          <v-card
            class="d-flex flex-row pa-2 rounded-lg green darken-5 justify-center"
            elevation="0"
            tile
          >
            <div class="d-flex flex-column">
              <div
                class="
                  d-flex
                  justify-center
                  pa-1
                  ml-2
                  text-h4
                  white--text
                  font-weight-bold
                "
              >
                {{ createdPPMP }}
              </div>
              <v-divider class="white"></v-divider>
              <div
                class="
                  pa-2
                  text-overline
                  font-weight-bold
                  d-flex
                  justify-center
                  white--text
                "
              >
                Created PPMP's
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card
            class="d-flex flex-row pa-2 rounded-lg orange justify-center"
            elevation="0"
            tile
          >
            <div class="d-flex flex-column">
              <div
                class="
                  d-flex
                  justify-center
                  pa-1
                  ml-2
                  text-h4
                  white--text
                  font-weight-bold
                "
              >
                {{ approvedPPMP }}
              </div>
              <v-divider class="white"></v-divider>
              <div
                class="
                  pa-2
                  text-overline
                  font-weight-bold
                  d-flex
                  justify-center
                  white--text
                "
              >
                Approved PPMP's
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card
            class="d-flex flex-row pa-2 rounded-lg yellow darken-4 justify-center"
            elevation="0"
            tile
          >
            <div class="d-flex flex-column">
              <div
                class="
                  d-flex
                  justify-center
                  pa-1
                  ml-2
                  text-h4
                  white--text
                  font-weight-bold
                "
              >
                {{ pendingPPMP }}
              </div>
              <v-divider class="white"></v-divider>
              <div
                class="
                  pa-2
                  text-overline
                  font-weight-bold
                  d-flex
                  justify-center
                  white--text
                "
              >
                Pending PPMP's
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card
            class="
              d-flex
              flex-row
              pa-2
              rounded-lg
              grey
              darken-2
              justify-center
            "
            elevation="0"
            tile
          >
            <div class="d-flex flex-column">
              <div
                class="
                  d-flex
                  justify-center
                  pa-1
                  ml-2
                  text-h4
                  white--text
                  font-weight-bold
                "
              >
                {{ createdPR }}
              </div>
              <v-divider class="white"></v-divider>
              <div
                class="
                  pa-2
                  text-overline
                  font-weight-bold
                  d-flex
                  justify-center
                  white--text
                "
              >
                Created PR's
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card
            class="d-flex flex-row pa-2 rounded-lg green justify-center"
            elevation="0"
            tile
          >
            <div class="d-flex flex-column">
              <div
                class="
                  d-flex
                  justify-center
                  pa-1
                  ml-2
                  text-h4
                  white--text
                  font-weight-bold
                "
              >
                {{ approvedPR }}
              </div>
              <v-divider class="white"></v-divider>
              <div
                class="
                  pa-2
                  text-overline
                  font-weight-bold
                  d-flex
                  justify-center
                  white--text
                "
              >
                Approved PR's
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="4">
          <v-card
            class="d-flex flex-row pa-2 rounded-lg orange justify-center"
            elevation="0"
            tile
          >
            <div class="d-flex flex-column">
              <div
                class="
                  d-flex
                  justify-center
                  pa-1
                  ml-2
                  text-h4
                  white--text
                  font-weight-bold
                "
              >
                {{ pendingPR }}
              </div>
              <v-divider class="white"></v-divider>
              <div
                class="
                  pa-2
                  text-overline
                  font-weight-bold
                  d-flex
                  justify-center
                  white--text
                "
              >
                Pending PR's
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
  </div>
</template>

<script>
import BarChart from "../Charts/BarChart.vue";
// import DoughnutChart from "../Charts/DoughnutChart.vue";
export default {
  components: {
      BarChart,
      // DoughnutChart,
  },
  props: {
    fiscalYear: null
  },
  data: () => ({
    items1: [
      {
        colors: "green darken-1",
        icon: "mdi-cash",
        text: "Budgets Available",
        counter: "0",
      },
      {
        colors: "orange darken-1",
        icon: "mdi-cash-fast",
        text: "Used Budget",
        counter: "0",
      },
    ],
    items2: [
      {
        colors: "grey darken-1",
        icon: "mdi-cash-multiple",
        text: "Unused Budget",
        counter: "0",
      },
      {
        colors: "amber darken-1",
        icon: "mdi-scale-balance",
        text: "Overall Budget",
        counter: "0",
      },
    ],
    createdPPMP: 0,
    approvedPPMP: 0,
    pendingPPMP: 0,
    createdPR: 0,
    approvedPR: 0,
    pendingPR: 0,
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    value: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    prChartData_count: 0,
    mpr_data: [],
  }),
  watch: {
    fiscalYear: {
      handler() {
        this.initialize();
      },
      deep: true,
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize(){
      if(this.fiscalYear != null){
      this.getBudgetAvailable();
      this.getOverallBudget();
      this.getUnusedBudget();
      this.getUsedBudget();
      this.getCreatedPPMPCount();
      this.getApprovedPPMPCount();
      this.getPendingPPMPCount(); 
      this.getCreatedPR();
      this.getApprovedPR();
      this.getPendingPR();
      this.prChartDataByUser();
      }
    },
    getBudgetAvailable() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/budgetsAvailableByUser", "POST", data).then(
        (res) => {
          this.loading = false;
          this.items1[0].counter = res.data.data.result[0].budgetAvailable;
        }
      );
    },
    getOverallBudget() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/overallBudgetByUser", "POST", data).then(
        (res) => {
          this.loading = false;
          this.items2[1].counter =
            "PHP " + res.data.data.result[0].overallBudget;
        }
      );
    },
    getUnusedBudget() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/unusedBudgetByUser", "POST", data).then(
        (res) => {
          this.loading = false;
          this.items2[0].counter =
            "PHP " + res.data.data.result[0].unusedBudget;
        }
      );
    },
    getUsedBudget() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/usedBudgetByUser", "POST", data).then((res) => {
        this.loading = false;
        this.items1[1].counter = "PHP " + res.data.data.result[0].usedBudget;
      });
    },
    getPendingPRCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/pendingPR", "POST", data).then((res) => {
        this.loading = false;
        this.pr_items1[1].counter = res.data.data.result[0].PendingPR;
      });
    },
    getApprovedPRCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/approvedPR", "POST", data).then((res) => {
        this.loading = false;
        this.pr_items1[0].counter = res.data.data.result[0].ApprovedPR;
      });
    },
    getPendingPOCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/pendingPO", "POST", data).then((res) => {
        this.loading = false;
        this.po_items1[1].counter = res.data.data.result[0].PendingPO;
      });
    },
    getApprovedPOCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/approvedPO", "POST", data).then((res) => {
        this.loading = false;
        this.po_items1[0].counter = res.data.data.result[0].ApprovedPO;
      });
    },
    getCreatedPPMPCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/createdPPMPByUser", "POST", data).then((res) => {
        this.loading = false;
        this.createdPPMP = res.data.data.result[0].createdPPMP;
      });
    },
    getApprovedPPMPCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/approvedPPMPByUser", "POST", data).then(
        (res) => {
          this.loading = false;
          this.approvedPPMP = res.data.data.result[0].approvedPPMP;
        }
      );
    },
    getPendingPPMPCount() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/pendingPPMPByUser", "POST", data).then((res) => {
        this.loading = false;
        this.pendingPPMP = res.data.data.result[0].pendingPPMP;
      });
    },
    getCreatedPR() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/createdPRByUser", "POST", data).then((res) => {
        this.loading = false;
        this.createdPR = res.data.data.result[0].createdPR;
      });
    },
    getApprovedPR() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/approvedPRByUser", "POST", data).then((res) => {
        this.loading = false;
        this.approvedPR = res.data.data.result[0].approvedPR;
      });
    },
    getPendingPR() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/pendingPRByUser", "POST", data).then((res) => {
        this.loading = false;
        this.pendingPR = res.data.data.result[0].pendingPR;
      });
    },
    prChartDataByUser() {
      let data = new FormData();
      data.append('fiscalYear', this.fiscalYear)
      this.axiosCall("/dashboard/prChartDataByUser", "POST", data).then((res) => {
        this.loading = false;
          let labels = [];
          let data = [];
          let total = 0;
          res.data.data.result.forEach(item => {
            labels.push(item.monthname + " " + item.year);
          });
          res.data.data.result.forEach(item => {
            data.push(item.totalcount);
            total = total + parseInt(item.totalcount);
          });
          this.mpr_data = {
            labels: labels,
            data: data,
            total: total,
            label: 'No. of Purchase Request',
          }
      });
    },
  },
};
</script>

<style scoped>
.Offset {
  top: -20px !important;
  position: relative !important;
}
.v-sheet--offset {
  top: -15px;
  position: relative;
}
</style>
